export default defineNuxtRouteMiddleware((to) => {
    const { isAuthenticated, verifyEmail } = useAuth();
    const config = useRuntimeConfig();
    if (isAuthenticated.value === false) {
        // use the id and hash to verify the user
        // and then redirect to the login page
        const id = to.query.id as string;
        const hash = to.query.hash as string;
        verifyEmail(id, hash);

        return navigateTo(config.public.loginUrl, { replace: true });
    }
});